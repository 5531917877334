var pawLibrary = {
	map: false,
	fbAccessToken: "1046599778716357|Ru5dCXFhXsqfy2-ziNbyZEGmOhs",
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	fullMonthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
  			$('.dropdown-menu').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});

		// third tier
		var secondTeir = $('#parent li a');
		var thirdTeir = $('ul.third');
		secondTeir.click(function(e) {
			e.preventDefault();
			var selected = $(this).attr('id').replace("thirdMenuParent", "");
			thirdTeir.removeClass('show').hide();
			secondTeir.removeClass('active');
			$('#third' + selected).fadeIn().addClass('show');
			$('#thirdMenuParent' + selected).addClass('active')
		});

		// hide mega menu
		$(document).click(function(e) {
			if($('#showMenu7').hasClass('highlight') == true) {
				var $container = $("header.page-header .btm");
		    if (!$container.is(e.target) && $container.has(e.target).length === 0) {
		       $('#showMenu7').trigger('click');
		    }
			}
				if($('#showMenu8').hasClass('highlight') == true) {
					var $container = $("header.page-header .btm");
			    if (!$container.is(e.target) && $container.has(e.target).length === 0) {
			       $('#showMenu8').trigger('click');
			    }
				}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: zoomLevel,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if(status == false)
				{
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					target.slideUp(function() {
						target.removeClass('open');
					});
				}
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function()
	{
		var enquiry_btn = $('#enquiry_btn');

		enquiry_btn.click(function(e) {
			var title = $('.productDetails h1').html();
			e.preventDefault();
			enquiry_btn.fancybox({
					fitToView	: false,
					autoSize	: true,
					type: 'ajax',
					padding: 0,
					href: 'frontend/view/inc/forms/enquiry.inc.php',
					beforeShow: function() {
						$('.fancybox-inner h3').html("Enquire About " + title);
        		$("body").css({'overflow-y':'hidden'});
					},
					afterClose: function() {
        		$("body").css({'overflow-y':'visible'});
					},
					afterShow: function() {
						var form = $('.fancybox-inner form');

						form.submit(function(e) {
							e.preventDefault();
							var loader = $('#loader');
							var data = {};
								data['name_txt'] = $('.fancybox-inner form #name_txt').val();
								data['email_txt'] = $('.fancybox-inner form #email_txt').val();
								data['telephone_txt'] = $('.fancybox-inner form #telephone_txt').val();
								data['message_txt'] = $('.fancybox-inner form #message_txt').val();
								data['pageName'] = title;
								data['list_txt'] = "";
								data['url'] = 'http://' + window.location.hostname + window.location.pathname;

							form.hide();
							loader.fadeIn();


							$.ajax({
								type: 'POST',
								url: './ajax/sendEnquiry.inc.php',
								data: data,
							}).done(function(response) {
								loader.hide();
								form.html(response).show();
							});

							return false;
						});
					}
				});


		});
	},

	onResize: function()
	{

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	products: function() {

	},

	setMinorTabs: function() {
		if($('#minor-tabs').length > 0) {
			var tabs = $('#minor-tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			btns.unbind().click(function(e) {
				var selected = $(this).attr('id').replace("btn_", "");
				if(current != selected) {
					// selected tab
					btns.removeClass('selected');
					$('#btn_' + selected).addClass('selected');
					// selected textarea
					$('#item_' + current).addClass('hidden');
					$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
					// updated current
					current = selected;
				}
			});

		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setTabs: function() {
		var btns = $('#tabs-parent-btns li');
		var content = $('.tab-content');
		var selected = 0;
		btns.click(function() {
			var clicked = $(this);
			selected = clicked.attr('id').replace("tab-btn", "");
			btns.removeClass('active');
			content.removeClass('show').hide();

			$('#tab-btn' + selected).addClass('active');
			$('#tab-content' + selected).fadeIn().addClass('active');
		});

		var topLevel = $('a.toggle');
		topLevel.click(function(e) {
	  	e.preventDefault();
	    var $this = $(this);
			topLevel.removeClass('active');
	    if ($this.next().hasClass('show')) {
	        $this.next().removeClass('show');
	        $this.next().slideUp(350);
	    } else {
	        $this.parent().parent().find('li .inner').removeClass('show');
					$this.parent().parent().find('li .inner').slideUp(350);
	        $this.next().toggleClass('show');
	        $this.next().slideToggle(350);
					$($this[0]).addClass('active');
	    }
		});
	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	productGallery: function() {
		if( $('#gallery-img-thumb').length > 0 ) {
			$('#productFeatureImg').on('cycle-next cycle-prev', function(e, opts) {
				$('#gallery-img-thumb').cycle('goto', opts.currSlide);
			});

			$('#gallery-img-thumb .thumb-outter').click(function(){
					var index = $('#gallery-img-thumb').data('cycle.API').getSlideIndex(this);
					$('#productFeatureImg').cycle('goto', index);
			});

		}
	},
	productSearch: function() {
		var cat = $('#cat_sel').val();
		var capacity = $('#capacity_sel').val();
		var type = $('#type_sel').val().toLowerCase();
		var loader = $('#loader');
		var url = false;
		var lookUp = "";

		$('ul.listProducts li').hide();

		var products = $('ul.listProducts li.show');
		products.removeClass('show');
		products = $('ul.listProducts li');

		url = pawLibrary.insertParam('cat_sel', cat, url);
		if(cat != 0)
		{
			lookUp += ".cat" + cat;
		}

		url = pawLibrary.insertParam('capacity_sel', capacity, url);
		if(capacity != '')
		{
			lookUp += ".cap" + capacity;
		}

		url = pawLibrary.insertParam('cat_sel', cat, url);
		if(type != '')
		{
			lookUp += "." + type;
		}

		if(((cat == 0) || (cat == '')) && (capacity == '') && (type == ''))
		{
			$('ul.listProducts li').addClass('show');
		} else {
			$(lookUp).addClass('show');
		}

		products = $('ul.listProducts li.show');
		products.fadeIn();

		pawLibrary.updateProductURL(url);
	},
	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&capacity_sel=&type_sel=';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	timeline: function()
	{
		$('#about-timeline-js').slick({
		  centerMode: true,
		  centerPadding: '60px',
		  slidesToShow: 3,
			nextArrow: '<span id="right-arrow-btn" class="right arrow"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="left-arrow-btn" class="left arrow"><i class="fa fa-angle-left"></i></span>',
		  responsive: [
		    {
		      breakpoint: 768,
		      settings: {
		        arrows: false,
		        centerMode: true,
		        centerPadding: '40px',
		        slidesToShow: 3
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        arrows: false,
		        centerMode: true,
		        centerPadding: '40px',
		        slidesToShow: 1
		      }
		    }
		  ]
		});
	},

	searchSuppliers: function() {
		var search = $('#location_txt');
		var location_btn = $('#location_btn');

		search.geocomplete().bind("geocode:result", function(event, result){
			//location_btn.trigger('click');
    })

		$('#tryAgain_btn').click(function() {
			$('#enter-location-results').hide();
			$('#enter-location-frm').fadeIn();
		});

		location_btn.click(function(e) {
			e.preventDefault();
			var address = search.val();
			var geocoder = new google.maps.Geocoder();
			var geo_lat = 0;
			var geo_lng = 0;

			geocoder.geocode( {
				'address': address
					}, function(results, status) {
						  if (status == google.maps.GeocoderStatus.OK)
						  {
								var p1 = results[0].geometry.location;
								var selected = 0;
								var current = 0;
								$.each(pawLibrary.markers, function(k,row) {
									var p2 = new google.maps.LatLng(row.lat, row.lng);
									var distance = pawLibrary.calculateDistance(k,p1,p2);
											distance = parseFloat(distance);
									pawLibrary.markers[k].distance = distance;
									if(k == 0) {
										current = parseFloat(distance);
										selected = k;
									} else {
										if(distance < current) {
											current = parseFloat(distance);
											selected = k;
										}
									}
								});

								$('#nearest-supplier-title').html(pawLibrary.markers[selected].title);
								$('#nearest-supplier-address').html(pawLibrary.markers[selected].address);
								$('#nearest-supplier-miles').html(pawLibrary.markers[selected].distance);

								$('#enter-location-frm').hide();
								$('#enter-location-results').fadeIn();


							}
			});

		});
	},

	calculateDistance: function(key, p1, p2)
	{
			var distance = (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2);
			var miles = distance * 0.6214;

			return parseFloat(Math.round(miles * 100) / 100).toFixed(2);

	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.banners();
		//pawLibrary.jumpToLinks();
		pawLibrary.productGallery();
		pawLibrary.dropdownMenus();
		pawLibrary.setGallery();

		if($('#recent-offers-wrap').length > 0) {
			$('#recent-offers-wrap').slick({
				infinite: true,
				speed: 300,
				slidesToShow: 3,
				slidesToScroll: 1,
				nextArrow: '#offer-next',
				prevArrow: '#offer-prev',
				responsive: [
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 500,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		}

		if($('#slick-items').length > 0) {
			$('#slick-items').slick({
			  infinite: true,
			  speed: 300,
			  slidesToShow: 5,
			  slidesToScroll: 1,
				nextArrow: '#brand-nxt-btn',
				prevArrow: '#brand-prev-btn',
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 4
						}
					},
					{
						breakpoint: 890,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});
		}

		if($('#tabs-parent-btns').length == 1) {
			pawLibrary.setTabs();
		}

		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			fancyBox.fancybox({
				padding: 0
			});
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

	}

}


$(window).load(function() {
	pawLibrary.init();
});
